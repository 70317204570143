<template>
    <div class="new-user">
        <div class="contentbox">
            <div class="titlebox">
                <div class="atitle">新用户信息登记</div>
            </div>
            <div class="form">
                <div class="form-item">
                    <label class="itemtitle" for="username">新用户名</label>
                    <input class="input" v-bind:class="{ 'active': tishi_sw == 'username' }" id="username" type="text"
                        maxlength="20" placeholder="3-20位的字母或数字" v-model="formInline.username" />
                </div>
                <!-- <div class="form-item">
                    <label class="itemtitle" for="password">您的密码</label>
                    <input class="input" v-bind:class="{ 'active': tishi_sw == 'password' }" id="password" type="password"
                        maxlength="20" placeholder="6-20位的字母+数字" v-model="formInline.password" />
                </div> -->
                <!-- <div class="form-item">
                    <label class="itemtitle" for="name">您的姓名</label>
                    <input class="input" v-bind:class="{ 'active': tishi_sw == 'name' }" id="name" type="text"
                        maxlength="20" placeholder="请输入您的姓名" v-model="formInline.name" />
                </div> -->
                <div class="form-item">
                    <div class="itemtitle">您的性别</div>
                    <div class="input" style="background: none;">
                        <div class="radio">
                            <input id="man" name="sex" checked type="radio" value="男" v-model="formInline.sex" />
                            <label for="man">男</label>
                        </div>
                        <div class="radio">
                            <input id="woman" name="sex" type="radio" value="女" v-model="formInline.sex" />
                            <label for="woman">女</label>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-item">
                    <label class="itemtitle" for="tel">联系电话</label>
                    <input class="input" v-bind:class="{ 'active': tishi_sw == 'tel' }" id="tel" type="text" maxlength="11"
                        placeholder="请输入您的联系方式" v-model="formInline.tel" />
                </div> -->
                <div class="form-item">
                    <label class="itemtitle" for="age">您的年龄</label>
                    <input class="input" v-bind:class="{ 'active': tishi_sw == 'age' }" id="age" type="text" maxlength="3"
                        placeholder="请输入您的年龄" v-model="formInline.age" />
                </div>
                <!-- <div class="form-item">
                    <div class="itemtitle2" style="flex: 1;">是否同意我院专业人员电话回访</div>
                </div>
                <div class="form-item">
                    <div class="input" style="margin-left: -3rem; background: none;">
                        <div class="radio">
                            <input id="yes" checked name="tongyi" type="radio" value="是" v-model="formInline.agree" />
                            <label for="yes">是</label>
                        </div>
                        <div class="radio">
                            <input id="no" name="tongyi" type="radio" value="否" v-model="formInline.agree" />
                            <label for="no">否</label>
                        </div>
                    </div>
                </div> -->
                <div class="detail">
                    <div class="itemtitle">
                        <div class="atitle">心理健康描述</div>
                        <div class="btitle">{{ formInline.detail.length }}/200</div>
                    </div>
                    <textarea v-model="formInline.detail" v-bind:class="{ 'active': tishi_sw == 'detail' }" class="textarea"
                        maxlength="200" placeholder="请简单描述一下您最近的心理状态，或者您有什么问题想要了解、咨询。"></textarea>
                </div>
                <!-- <div class="tips">{{ base.tsTongyi }}</div> -->
                <p class="tips-title">小贴士</p>
                <p class="tips-con" style="white-space: pre-wrap;" v-html="base.tsTieShi" />
                <div class="btns">
                    <button class="btn" @click="register" v-loading.fullscreen.lock="fullscreenLoading">提交信息</button>
                    <!-- <button class="btn" @click="back">返回登录</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBase, zhuce } from "@/js/request";
import { denglu, getWxUrl, getLoginBack, getUserInfo, getWebrtcParams } from "@/js/request"
import { ElMessage } from 'element-plus'

// @ is an alias to /src
// import { ElForm, ElFormItem, ElInput, ElRadioGroup, ElRadio } from 'element-plus'
export default {
    name: 'NewUser',
    components: {
        // ElForm,
        // ElFormItem,
        // ElInput,
        // ElRadioGroup,
        // ElRadio
    },
    data() {
        return {
            base: {},
            openid: '',
            formInline: {
                username: '',
                password: '',
                name: '',
                sex: '男',
                tel: '',
                age: '',
                agree: '是',
                detail: '',
            },

            tishi_sw: '',
            fullscreenLoading: false,

        }
    },
    methods: {
        back() {
            this.$router.replace('/home');
        },
        register() {
            var that = this;
            that.tishi_sw = '';
            if (that.formInline.username == '') {
                that.tishi_sw = 'username';
                return;
            } else if (that.formInline.age == '') {
                that.tishi_sw = 'age';
                return;
            }
            that.fullscreenLoading = true;
            zhuce({
                "method": 0,
                "codeKey": "test",
                "codeValue": "test",
                "siteId": 1,
                "userName": that.formInline.username,
                "password": "test123456",
                "realName": that.formInline.username,
                "phone": "",
                "sex": that.formInline.sex,
                "age": that.formInline.age ? that.formInline.age : 0,
                "hui": that.formInline.agree,
                "remark": that.formInline.detail,
                "openid": that.openid,
            }).then(res => {
                console.log(res);
                that.errorts = '恭喜您注册成功';
                ElMessage({
                    message: that.errorts,
                    type: 'success',
                    "on-close": function () {
                        that.toLogin();
                    }
                })
            }).catch(res => {
                that.fullscreenLoading = false
                if (Array.isArray(res.response.data.message)) {
                    that.errorts = res.response.data.message[0][0];
                } else {
                    that.errorts = res.response.data.message;
                }
                ElMessage({
                    message: that.errorts,
                    type: 'warning',
                })
                console.log(res);
            });
        },
        toLogin(){
            var that = this;
            denglu({ "codeKey": "test", "codeValue": "test", "password": "test123456", "userName": that.formInline.username }).then(res => {
                that.fullscreenLoading = false
                console.log(res);
                // that.errorts = '登录成功，跳转至问卷...';
                // ElMessage({
                //     message:that.errorts,
                //     type: 'success',
                // })  

                //设置登录token            
                this.$cookies.set("token", res.data.accessToken, 60 * 30);
                // this.$router.replace('/index?wen=1');
                const modelKey = window.localStorage.getItem('modelKey');
                getUserInfo(modelKey).then(res => {
                    window.localStorage.removeItem('modelKey');
                    const webrtcId = window.localStorage.getItem('webrtcId');
                    let webrtcIndex = window.localStorage.getItem('webrtcIndex');
                    if(webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                        webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == ""){
                        const depId = 3;
                        const jumpJrl = res.data.url + "&depId=" + depId;
                        console.log("jumpJrl=", jumpJrl)
                        window.location.href = jumpJrl;
                    }else{
                        webrtcIndex = webrtcIndex.split("?")[0];
                        
                        getWebrtcParams(webrtcId).then(WebrtcRes => {
                            const webrtcParams = WebrtcRes.data[webrtcIndex];
                            if(webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null){
                                this.telConnected = true;
                                return;
                            }
                            const depId = webrtcParams.articleFields[5].fieldValue;
                            const jumpJrl = res.data.url + "&depId=" + depId;
                            window.location.href = jumpJrl;
                        });
                    }
                }).catch(res => {
                    if (res.response.status == 401) {                    
                        this.$router.push('/home?value='+encodeURIComponent(modelKey));
                    } else {
                        alert(res.response.statusText);
                    }
                });


            }).catch(res => {
                that.fullscreenLoading = false
                if (Array.isArray(res.response.data.message)) {
                    that.errorts = res.response.data.message[0][0];
                } else {
                    that.errorts = res.response.data.message;
                }
                ElMessage({
                    message: that.errorts,
                    type: 'warning',
                })
                console.log(res);
            });            
        }
    },
    mounted() {
        this.$root.showRight();
        if (this.$route.query.openid) {
            this.openid = this.$route.query.openid;
        }
        getBase().then(res => {
            this.base = res.data;
        });

    }
}
</script>

<style lang="scss">
.new-user {
    @include sys-flex();
    width: 100vw;
    height: 100vh;

    .contentbox {
        @include sys-boxsize(1400px, 800px, 20px);
        @include sys-flex();
        box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
        overflow: hidden;
        padding: 0 350px;
        box-sizing: border-box;

        .titlebox {
            flex: none;
            text-align: center;

            .atitle {
                @include sys-text-hide(1);
                @include sys-font(36px, #000, bold);
                line-height: 38px;
                margin-bottom: 30px;
            }
        }

        .form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            overflow: hidden;

            .btns {
                width: 100%;
                @include sys-flex(center, row);
                padding-top: 30px;
                .btn {
                    @include sys-flex();
                    font-size: 20px;
                    margin-bottom: 20px;
                    cursor: pointer;


                    &:last-child {
                        @include sys-boxsize(340px, 60px, 6px);
                        border: 2px solid $acolor;
                        color: $acolor;
                    }
                    &:first-child {
                        @include sys-boxsize(340px, 60px, 6px, $acolor);
                        color: #fff;
                    }
                }
            }

            .tips {
                @include sys-text-hide(3);
                @include sys-font(16px, #999);
                line-height: 24px;
                margin: 24px 0;
            }
            .tips-title {
                @include sys-font(24px, #000);
                line-height: 46px;
            }
            .tips-con {
                @include sys-font(16px, #999);
                line-height: 24px;
            }

            .detail {
                width: 100%;
                padding-top: 10px;
                // .itemtitle:before{
                //     content:'*';
                //     color: red;
                // }
                .itemtitle {
                    @include sys-flex(space-between, row);
                    width: 100%;
                    line-height: 22px;
                    margin-bottom: 20px;

                    .atitle {
                        flex: 1;
                        @include sys-text-hide(1);
                        @include sys-font(20px, #000);
                    }

                    .btitle {
                        flex: none;
                        @include sys-text-hide(1);
                        @include sys-font(20px, #999);
                        letter-spacing: 0;
                    }
                }

                .textarea {
                    @include sys-font(20px, #000);
                    width: 100%;
                    height: 120px;
                    background: #F8FAFE;
                    border-radius: 6px;
                    padding: 15px 20px;
                    box-sizing: border-box;

                    &.active {
                        border: 1px solid #ff0000;
                    }
                }
            }

            // .agree {
            //     display: flex;
            //     align-items: center;
            //     height: 40px;
            //     margin-bottom: 10px;
            //     @include sys-font(20px, #666);
            //     .input {
            //         @include sys-radius-btn(20px, 20px, #F8FAFE);
            //         margin-right: 10px;
            //         opacity: 0;
            //     }
            //     .itemtitle {
            //         position: relative;
            //         &::before {
            //             content: '';
            //             position: absolute;
            //             top: 50%;
            //             left: -30px;
            //             @include sys-radius-btn(20px, 20px, #F8FAFE);
            //             border: 2px solid #BCC3D8;
            //             transform: translateY(-50%);
            //             box-sizing: border-box;
            //         }
            //     }
            //     input:checked + .itemtitle::before {
            //         background: url(../assets/img/yes.png) no-repeat center center/contain;
            //         border: none;
            //     }
            // }
            .form-item {
                @include sys-font(20px, #000);
                @include sys-flex(space-between, row);
                width: 325px;
                height: 60px;
                text-align: left;
                margin-bottom: 15px;

                .itemtitle:before {
                    content: '*';
                    color: red;
                }

                .itemtitle {
                    width: 105px;
                    line-height: 60px;
                    @include sys-text-hide(1);
                }

                .itemtitle2 {
                    width: 105px;
                    line-height: 60px;
                    @include sys-text-hide(1);
                }

                .input {
                    @include sys-font(20px, #000);
                    display: flex;
                    width: 220px;
                    height: 100%;
                    padding: 0 20px;
                    box-sizing: border-box;
                    background: #F8FAFE;
                    border-radius: 6px;

                    &.active {
                        border: 1px solid #ff0000;
                    }

                    .radio {
                        @include sys-flex(space-between, row);
                        width: 80px;
                        height: 100%;
                        padding-left: 10px;
                        padding-right: 20px;
                        box-sizing: border-box;

                        label {
                            position: relative;
                            cursor: pointer;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: -30px;
                                @include sys-radius-btn(20px, 20px, #F8FAFE);
                                border: 2px solid #BCC3D8;
                                transform: translateY(-50%);
                                box-sizing: border-box;
                            }
                        }

                        input:checked+label::before {
                            background: url(../assets/img/yes.png) no-repeat center center/contain;
                            border: none;
                        }

                        input {
                            @include sys-radius-btn(20px, 20px, #F8FAFE);
                            border: 2px solid #BCC3D8;
                            margin-right: 10px;
                            opacity: 0;

                        }
                    }
                }
            }

            // .el-form-item {
            //     display: flex;
            //     align-items: center;
            //     width: 325px;
            //     height: 60px;
            //     text-align: left;
            //     .el-form-item__label {
            //         @include sys-font(20px, #000);    
            //         flex: 1;

            //     }
            //     .el-input {
            //         flex: none;

            //     }
            // }
        }
    }
}
</style>